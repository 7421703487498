.timelapse-btn > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 7vh;
  cursor: pointer;
  z-index: 1;
  filter: drop-shadow(0px 1.66667px 3.33333px #000000);
  cursor: pointer !important;
}

.timelapse-btn > svg.btn-background {
  z-index: 0;
  transition: opacity 0.2s ease;
  height: 5vh;
  width: auto;
}

.timelapse-bar {
  position: fixed;
  left: 6vw;
  bottom: 12vh;

  width: auto;
  height: 4vw;
  padding: 1.4vw 1.2vw 0.2vw 1.2vw;

  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  transition: opacity 0.3s ease;
}

.timelapse-bar > svg {
  width: 1.8vw;
  height: auto;

  cursor: pointer;
}

.timelapse-bar > svg > * {
  fill: #101010;
}

.timelapse-bar > .line {
  width: 12vw;
  border: 0;
  background-color: #101010;
  height: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}

.timelapse-bar > .line > .time-selector {
  width: 0.8vw;
  height: 0.8vw;
  background: #ffffff;
  border: 1px solid #101010;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.timelapse-bar > .line > .time-selector.selected {
  width: 1.5vw;
  height: 1.5vw;
  border: 2px solid #101010;
}

.timelapse-bar > .line > .time-selector > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -310%);
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 100%;
  /* identical to box height, or 100% */

  /* black */

  color: #101010;

  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.timelapse-bar > .line > .time-selector.selected > p {
  opacity: 1;
}
