.share > .shareButton > path {
  fill: #ffffff;
}

.share {
  width: 24px;
  height: 24px;
}

.share > .shareButton {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  /*filter: drop-shadow(0px 1.66667px 3.33333px #000000);*/
  z-index: 1;
  position: relative;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.share > svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: auto;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.share > svg * {
  filter: none;
}

.shared {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  pointer-events: none;
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  transition: all 0.5s ease;
}

.share > .arrow-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share > .arrow-div > .info-tooltip {
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.share > .arrow-div > .arrow-down {
  width: 0;
  height: 0;
  border-left: 0.5vw solid transparent;
  border-right: 0.5vw solid transparent;
  border-top: 0.5vw solid #4a4a4a;
}
