.pano-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pano-info > .pano-info-container {
  position: fixed;
  bottom: 6.11vh;
  left: 3.23vw;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1vw;
}

.pano-info > .pano-info-container > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  text-shadow: 0px 1px 5px #000000;
  margin: 0;
}

.pano-info > .pano-info-container > .know-more-btn {
  box-sizing: border-box;
  padding: 1vw 2vw;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 89%;

  text-align: center;

  color: rgb(var(--main-color));
  background: #ffffff;

  cursor: pointer;
}

.pano-info > .pano-info-container > .know-more-btn:hover {
  background: rgb(var(--main-color));
  color: #ffffff;
}

.metadata,
.metadata > .author,
.metadata > .date,
.metadata > .coords {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.metadata svg {
  width: 34px;
  height: 34px;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
  margin-right: 5px;
}

.metadata svg > * {
  fill: #ffffff;
}

.metadata svg * {
  filter: none;
}

.mobile-metadata.metadata svg {
  width: 3vw;
  height: auto;
}

.metadata > .author > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;
  text-shadow: 0px 1px 5px #000000;
  margin-right: 20px;
}

.metadata > .date > p,
.metadata > .coords > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;

  text-shadow: 0px 1px 5px #000000;
  margin-right: 20px;
}

.mobile-metadata.metadata > .author > p,
.mobile-metadata.metadata > .date > p,
.mobile-metadata.metadata > .coords > p {
  font-size: 2vw;
}

.pano-info > .pano-info-container > .metadata > .author > p > b {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: normal;
}

.pano-info > .pano-info-btn > svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 24px;
  width: 24px;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.pano-info > .pano-info-btn > svg * {
  filter: none;
}

.pano-info > .pano-info-btn {
  width: 24px;
  height: 24px;
}

.pano-info > .pano-info-btn > .p-info {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.pano-info > .pano-info-btn > .p-info > * {
  fill: #ffffff;
}

.pano-info > .pano-info-btn > .p-info * {
  filter: none;
}

.pano-info > .pano-info-btn > .arrow-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pano-info > .pano-info-btn > .arrow-div > .info-tooltip {
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.pano-info > .pano-info-btn > .arrow-div > .arrow-down {
  width: 0;
  height: 0;
  border-left: 0.5vw solid transparent;
  border-right: 0.5vw solid transparent;
  border-top: 0.5vw solid #4a4a4a;
}

.know-more-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(72, 63, 50, 0.95);
  z-index: 200;
}

.know-more-panel > svg {
  position: absolute;
  top: 3vw;
  right: 5vw;
  width: 5vw;
  cursor: pointer;
}

.know-more-panel > svg > * {
  fill: #ffffff;
}

.know-more-panel > .know-more-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

.know-more-panel > .know-more-info > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 83%;
  /* identical to box height, or 83% */

  letter-spacing: -0.01em;

  /* White */

  color: #ffffff;
}

.know-more-panel > .know-more-info > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 142%;
  /* or 142% */

  color: #ffffff;

  text-shadow: 0px 1px 2px #000000;
}

.mobile-know-more-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(72, 63, 50, 0.95);
  z-index: 350;
}

.mobile-know-more-panel > svg {
  position: absolute;
  top: 2vw;
  right: 2vw;
  width: 6vw;
  z-index: 1;
}

.mobile-know-more-panel > svg > * {
  fill: #ffffff;
}

.mobile-know-more-panel > .mobile-know-more-info {
  position: absolute;
  top: 0;
  bottom: 0;
  max-height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15vw;
  z-index: 0;
}

.mobile-know-more-panel > .mobile-know-more-info > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4.5vw;
  line-height: 111%;
  /* or 111% */

  letter-spacing: -0.01em;

  /* White */

  color: #ffffff;
  margin-bottom: 0;
  padding: 0;
}

.mobile-know-more-panel > .mobile-know-more-info > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 189%;

  color: #ffffff;

  overflow: auto;

  height: 45vh;
  padding-right: 2vw;
  margin-top: 5vh;
}
