.navBar-modal-covilha {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 99;
  transition: width 0.5s ease;
  height: 70vh;
  width: 65vw;
}

.modal-covilha {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 99;
  transition: width 0.5s ease;
  max-height: 95vh;
}

.navBar-modal-covilha > .modal-wrapper > .main-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-covilha > .modal-wrapper > .main-img > img {
  margin: 6vh 2vw 6vh 0;
  max-height: 100%;
}

.navBar-modal-covilha > .modal-wrapper,
.modal-covilha > .modal-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70%;
  padding: 2vw 2.5vw 0 2.5vw;
}

.main-img {
  position: relative;
  width: 65%;
  height: 100%;
  overflow: hidden;
}

.information {
  position: relative;
  width: 35%;
  height: 100%;
}

.modal-covilha > h1,
.navBar-modal-covilha > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.5vw;
  line-height: 98%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 2.5vw;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navBar-modal-covilha > .modal-wrapper > .information > p,
.modal-covilha > .modal-wrapper > .information > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #141414;

  overflow-y: scroll;

  height: 100%;
  padding-right: 1vw;

  margin-top: 0;
}

.information > .logoCov {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40%;
  height: auto;
  transform: translateX(-50%);
}

.navBar-modal-covilha > svg,
.modal-covilha > svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.modal-covilha > h1 > svg,
.navBar-modal-covilha > h1 > svg {
  width: 2.8vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
  cursor: pointer;
}

.modal-covilha > h1 > svg > *,
.navBar-modal-covilha > h1 > svg > * {
  fill: #ffffff;
}

.mobile-modal-covilha {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-covilha > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 4vw;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobile-modal-covilha > h1 > svg {
  width: 4.9vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
}

.mobile-modal-covilha > .mobile-content-covilha {
  height: 88.5%;
  padding: 0 4vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobile-modal-covilha > .mobile-content-covilha > * {
  flex-shrink: 0;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-text {
  flex: 1;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-text > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 144%;
  color: #000000;
  margin-top: 0;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-modal-img {
  flex: 1;
  width: 100%;
  height: 80%;
  margin-bottom: 0;
  overflow: hidden;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-modal-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-text > .logoCov {
  width: 40%;
  height: auto;
  margin-bottom: 2vw;
}

.mobile-modal-covilha > .mobile-content-covilha > svg {
  width: 100%;
  height: auto;
}
