.dropdown-circuit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  text-align: center;

  color: #ffffff;

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);

  margin: 0;
  padding: 0;

  cursor: pointer;
  transition: opacity 0.5s ease;
}

.dropdown-circuit.drop {
  opacity: 0;
  pointer-events: none;
}

.dropdown-circuit > svg {
  width: 24px;
  height: auto;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
}

.dropdown-circuit > svg > * {
  fill: #ffffff;
}

.dropdown-circuit > svg * {
  filter: none;
}

.circuits {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0.1vh;
  background: rgba(65, 65, 65, 0.7);
  opacity: 0;
  border-radius: 20px;
  padding: 0 0;
  transition: height 0.5s ease, opacity 0.5s ease;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.circuits.drop {
  z-index: 1;
  opacity: 0.75;
  pointer-events: all;
}

.circuits > p {
  margin: 0;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  width: 100%;

  /* white */

  color: #ffffff;
  padding: 10px 21px;
}

.circuits > p:hover {
  background: rgb(var(--main-color));
  cursor: pointer;
}

.dropdown-background {
  position: fixed;
  top: -4.63vh;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}

.circuits-menu {
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.circuits-menu > .menu {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 45px);
  width: calc(var(--vw, 1vw) * 100);
  background: rgb(var(--main-dark));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.circuits-menu > .menu > .choose-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.circuits-menu > .menu > .choose-label > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;

  /* white */

  color: #ffffff;
  margin: 0;
}

.circuits-menu > .menu > .choose-label > svg {
  width: 55px;
  height: 2px;
  margin-right: 16px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.circuits-menu > .menu > .choose-label > svg > * {
  stroke: #ffffff;
  stroke-width: 2;
}

.circuits-menu > .menu > .m-circuits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 88px;
  margin: 32px 0 15.7vh 88px;
}

.circuits-menu > .menu > .m-circuits > p:first-child {
  margin: 0;
}

.circuits-menu > .menu > .m-circuits > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  /* white */

  color: #ffffff;
  margin: 36px 0 0 0;
}

.circuits-menu > .footer-bar {
  width: 100vw;
  height: 45px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.circuits-menu > .footer-bar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.circuits-menu > .footer-bar > svg > * {
  fill: #000004;
}

.circuits-menu > .footer-bar > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  /* black */

  color: #101010;
  margin: 0;
}
