.hotspot-label.up .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hotspot-label.left .content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hotspot-label.down .content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.hotspot-label.right .content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.hotspot-label .content h3 {
  flex: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 30px;
  line-height: 35px;
  /* or 117% */

  text-align: center;

  /*color: rgb(var(--main-color));*/
  color: #ffffff;
  white-space: pre;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.hotspot-label .content .pointer {
  flex: 1;
  margin: 0;
  z-index: 99;
  display: block;
}

.hotspot-label .content .pointer img {
  width: 45px;
  height: 45px;
}

.hotspot-label.up .content .pointer img,
.hotspot-label.up > .content > .pointer > svg {
  transform: rotate(0);
}

.hotspot-label.left .content .pointer img,
.hotspot-label.left > .content > .pointer > svg {
  transform: rotate(270deg);
}

.hotspot-label.down .content .pointer img,
.hotspot-label.down > .content > .pointer > svg {
  transform: rotate(180deg);
}

.hotspot-label.right .content .pointer img,
.hotspot-label.right > .content > .pointer > svg {
  transform: rotate(90deg);
}

/*.hotspot-label > .content > .pointer > svg {
}*/

.hotspot-label > .content > .pointer > svg * {
  /*fill: rgb(var(--main-color));*/
  fill: #ffffff;
  filter: none;
}
