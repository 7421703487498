.decor {
  position: fixed;
  top: 14.63vh;
  right: 2.4vw;
  z-index: 99;
}

.decor > svg {
  width: 60px;
  height: auto;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: all;
}

.decor > svg.active {
  opacity: 0;
  pointer-events: none;
}

.decor > svg * {
  filter: none;
}

.decor > .decor-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  background: rgba(255, 255, 255, 0.85);
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 21.82vw;
  height: 73.15vh;
  transition: opacity 0.3s ease;
}

.decor > .decor-container.active {
  opacity: 1;
  pointer-events: all;
}

.decor > .decor-container > .search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.78vh;
  margin-bottom: 0.74vh;
}

.decor > .decor-container > .search-bar > svg {
  width: 24px;
}

.decor > .decor-container > .search-bar > svg > * {
  fill: #000004;
}

.decor > .decor-container > .search-bar > input {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.7);
  border: none;
  outline: none;
  background: transparent;
}

.decor > .decor-container > .styles-container {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.decor > .decor-container > .styles-container > .style-container:first-child {
  margin-top: 0;
}

.decor > .decor-container > .styles-container > .style-container {
  margin-top: 16px;
  height: 23.89vh;
}

.decor > .decor-container > .styles-container > .style-container > img {
  width: 100%;
  height: 100%;
}

.decor > .decor-container > .styles-container > .style-container > .over {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

.decor > .decor-container > .styles-container > .style-container > .over > p {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 145%;
  padding: 16px;
  color: #ffffff;
}

svg.active > * {
  fill: rgb(var(--main-color));
}

.modal-decor {
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  background: rgba(35, 35, 35, 0.5);
}

.modal-decor > .decor-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 75%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  overflow: hidden;
}

.modal-decor > .decor-container > .search-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modal-decor > .decor-container > .search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}

.modal-decor > .decor-container > .search-bar > svg {
  width: 24px;
}

.modal-decor > .decor-container > .search-bar > svg > * {
  fill: #000004;
}

.modal-decor > .decor-container > .search-bar > input {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.7);
  border: none;
  outline: none;
  background: transparent;
  margin: 0 12px;
}

.modal-decor > .decor-container > .styles-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
}

.modal-decor > .decor-container > .styles-container > .style-container:first-child {
  margin-top: 0;
}

.modal-decor > .decor-container > .styles-container > .style-container {
  width: 100%;
  height: 71.67vh;
  margin-top: 16px;
}

.modal-decor > .decor-container > .styles-container > .style-container > img {
  width: 100%;
  height: 100%;
}

.modal-decor > .decor-container > .styles-container > .style-container > .over {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

.modal-decor > .decor-container > .styles-container > .style-container > .over > p {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 145%;
  padding: 16px;
  color: #ffffff;
}
