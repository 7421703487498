.invitation-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  height: 673px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

@media only screen and (max-width: 720px) {
  .invitation-container {
    height: 250px !important;
    overflow: auto;
  }
}

.invitation-container > h1 {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin: 0;
  padding: 0;
}

.invitation-container > p {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin-bottom: 0;
}

.invitation-container > .url {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 16px;
}

.invitation-container > .url > input {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #000000;
  border: none;
  width: 100%;
}

.invitation-container > .url > input:focus {
  outline: none;
}

.invitation-container > .url > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.invitation-container > .url > svg > * {
  fill: #000000;
}

.invitation-container > form {
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invitation-container > form > label {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
}

.invitation-container > form > input.name {
  height: 48px;
  width: 100%;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #808080;
  padding: 12px;
  margin-top: 16px;
}

.invitation-container > form > input:focus {
  outline: none;
}

.invitation-container > form > input.enter-btn {
  width: 100%;
  height: 48px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(var(--main-color));
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitation-container > .video {
  background: #000004;
  border-radius: 5px;
  height: 236px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitation-container > .video > video {
  width: 100%;
  height: auto;
  border-radius: 0px;
}

.invitation-container > .video-buttons {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.invitation-container > .video-buttons > .btn {
  width: 48px;
  height: 48px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
}

.invitation-container > .video-buttons > .btn:last-child {
  margin-right: 0px;
}

.invitation-container > .video-buttons > .btn > svg {
  width: 24px;
  height: 24px;
}

.invitation-container > .video-buttons > .btn > svg > * {
  fill: #000004;
}
