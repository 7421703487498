header {
  background: #f0293e;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 2em;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  background: #ffffff;
  flex-grow: 1;
}

form {
  max-width: 300px;
  margin: 0 auto;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

form > div {
  width: 100%;
  margin-bottom: 1em;
}
form > div > label {
  display: block;
  margin-bottom: 0.3em;
}
form > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #333e5a;
}

button {
  background: #333e5a;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}
button:hover {
  filter: brightness(150%);
}

.room {
  position: fixed;
  bottom: 10vh;
  left: 3.13vw;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: 292px;
  z-index: 101;
}

@media only screen and (max-width: 720px) {
  .room {
    width: 62px;
    bottom: 15vh;
  }

  .local-participant {
    height: 62px !important;
  }

  .remote-participants > .participant {
    width: 100% !important;
    height: 62px !important;
  }

  .participant > .video-chat-container > video {
    height: 100% !important;
    width: 62px !important;
  }

  .participant > .remote-bg > .remote-tools {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .participant > .remote-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 77, 77, 0.3);
  }

  .video-overlay {
    width: 62px !important;
    height: 62px !important;
  }
}

.room::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}
.room button {
  position: absolute;
  top: 0;
  right: 20px;
}
.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.local-participant {
  width: 100%;
  height: 170px;
  text-align: center;
}
.remote-participants {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  pointer-events: all;
  /*max-height: 380px;
  overflow-y: auto;*/
}
.remote-participants::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}
.remote-participants > .participant {
  height: 142px;
  margin-bottom: 8px;
}
.participant {
  background: none;
  border-radius: 5px;
  display: inline-block;
  pointer-events: all;
  height: 100%;
}
.participant:first-child {
  padding-left: 0;
}
.participant:last-child {
  margin-right: 0;
  padding-right: 0;
}
.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}

.video-chat-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
}

.video-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000004;
  color: #ffffff;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 4px;
}

.local-participant > .participant > .video-chat-container > video {
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  transform: scale(-1, 1);
  width: 100%;
  height: auto;
}

.video-chat-container > video {
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  transform: scale(-1, 1);
  height: 100%;
}

.remote-video-buttons-container {
  position: absolute;
  bottom: 0;
}

footer {
  background: #333e5a;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  padding: 1em 0;
}

footer a {
  color: #fff;
}

.timer {
  position: fixed;
  bottom: 2vh;
  left: 50%;
  transform: translate(-50%);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #ffffff;
  z-index: 1;
  pointer-events: none;
}

.alert-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
  z-index: 1;
}

.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 1;
}

.alert > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: #000004;
}

.alert > .ok {
  text-align: right;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: rgb(var(--main-color));
  cursor: pointer;
}
