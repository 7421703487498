.header-container {
  height: 48px;
  display: flex;
  width: 100%;

  .title-container {
    background-color: rgb(var(--main-color));
    display: flex;
    align-items: center;
    width: 85.5%;
    padding-left: 4.8%;
  }

  .close-box-container {
    display: flex;
    background-color: rgb(var(--main-dark));
    align-items: center;
    justify-content: center;
    width: 14.5%;
    cursor: pointer;
  }
}

.mob-header-container {
  height: 48px;
  display: flex;
  width: 100%;

  .title-container {
    background-color: rgb(var(--main-color));
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 16px;
  }

  .close-box-container {
    display: flex;
    background-color: rgb(var(--main-dark));
    align-items: center;
    justify-content: center;
    width: 48px;
    cursor: pointer;
  }
}
