.marzipano {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0s ease-in;
}

.marzipano.show {
  opacity: 1;
  z-index: 1;
  pointer-events: all;
  transition: opacity 1s ease-in;
}
