.logo-container {
  position: fixed;
  top: 4.63vh;
  left: 3.13vw;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo-container > .main-logo {
  width: 100px;
  height: auto;
}

.logo-container > .back-container {
  width: 24px;
  height: 24px;
  margin-right: 0.83vw;
}

.logo-container > .back-container > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
}

.logo-container > .back-container > svg:hover {
  opacity: 1;
}
