.video-buttons-container {
  position: fixed;
  left: 50%;
  bottom: 10vh;
  transform: translate(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background: #000004;
  opacity: 0.85;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 8px;
}

@media only screen and (max-width: 720px) {
  .video-buttons-container {
    width: 220px;
    bottom: 15vh;
  }
}

.video-buttons-container > .btn-wrap {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 8px;
}

.video-buttons-container > .btn-wrap:first-child {
  margin-left: 0px;
}

.video-buttons-container > .btn-wrap > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.video-buttons-container > .btn-wrap > svg > * {
  fill: #ffffff;
}

.btn-wrap.red {
  background: #de4747;
}

.btn-wrap.green {
  background: #335610;
}

.btn-wrap.gray {
  background: #939393;
}

.btn-wrap > svg.green > * {
  fill: #92de47;
}

.live-feed {
  position: fixed;
  bottom: 5vh;
  left: 50%;
  transform: translate(-50%);
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.55);
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #000004;
  padding: 8px 16px;
}

.live-feed.guide {
  background: #92de47;
}

.live-feed.guided {
  background: #e67373;
}

.collapse {
  position: absolute;
  top: 50%;
  left: 105%;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, 0.3);
  border-radius: 3.18182px;
  cursor: pointer;
}

.collapse > svg {
  width: 24px;
  height: 24px;
}

.collapse > svg > * {
  fill: #ffffff;
}

.collapsed {
  position: fixed;
  left: 50%;
  bottom: 10vh;
  transform: translate(-50%);
}

@media only screen and (max-width: 720px) {
  .collapsed {
    bottom: 15vh;
  }
}

.collapsed > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.collapsed > svg > * {
  fill: #000004;
}

.finish-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
}

.finish-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.finish-container > h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  /* identical to box height, or 80% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.finish-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.finish-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.finish-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: #000004;
  width: 116px;
  height: 36px;
}

.finish-container > .buttons > .terminar {
  cursor: pointer;
  background: #de4747;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;
  width: 116px;
  height: 36px;
}

.live-mode-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  height: 277px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.live-mode-container > svg {
  width: 70px;
  height: 70px;
}

.live-mode-container > svg > * {
  fill: #000000;
}

.live-mode-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: start;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.live-mode-container > p > svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  vertical-align: text-bottom;
}

.live-mode-container > p > svg > * {
  fill: #000000;
}

.live-mode-container > p > strong {
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 20px;
}

.live-mode-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.live-mode-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: #000004;
  width: 116px;
  height: 36px;
}

.live-mode-container > .buttons > .continue {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: rgb(var(--main-color));
  width: 116px;
  height: 36px;
}

.invite-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  height: 229px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.invite-container > h1 {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin: 0;
  padding: 0;
}

.invite-container > p {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin-bottom: 0;
}

.invite-container > .url {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 16px;
}

.invite-container > .url > input {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #000000;
  border: none;
  width: 100%;
}

.invite-container > .url > input:focus {
  outline: none;
}

.invite-container > .url > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.invite-container > .url > svg > * {
  fill: #000000;
}

.invite-container > .copy-btn {
  width: 100%;
  height: 48px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(var(--main-color));
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
