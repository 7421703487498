.group-container > svg > * {
  fill: #ffffff;
}

.group-container > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.group-container {
  width: 24px;
  height: 24px;
}

.invitation-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.9);
  z-index: 1;
}

.mobile-navBar > .group-container {
  margin-right: 16px;
}

.mobile-navBar > .group-container > svg {
  width: 24px;
  height: 24px;
  filter: none;
}

.mobile-navBar > .group-container > svg > * {
  fill: #ffffff;
}
