.portrait {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20vw 8vw 8vw 8vw;
}

.portrait > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portrait > .background > img {
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-58%, -50%);
  -ms-transform: translate(-58%, -50%);
  transform: translate(-58%, -50%);
  width: auto;
  height: 100%;
}

.portrait > .logo {
  position: fixed;
  top: 4vw;
  left: 4vw;
  z-index: 1;
  width: 14vw;
}

.portrait > h1 {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 8vw;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  letter-spacing: 0.4vw;
}

.portrait > p {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3.5vw;
  font-weight: lighter;
  line-height: 1.4;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-top: 4vw;
  letter-spacing: 0.4vw;
  white-space: pre-wrap;
}

.portrait > .explore-btn {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3.5vw;
  font-weight: lighter;
  line-height: 4;
  color: #ffffff;
  margin: 0;
  margin-top: 8vw;
  text-align: center;
  letter-spacing: 0.4vw;
  background: rgb(var(--main-color));
  width: 50vw;
}

.portrait > .moreInfo {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3.5vw;
  font-weight: lighter;
  line-height: 1.4;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-top: 8vw;
  letter-spacing: 0.4vw;
}

.portrait > .moreInfoContent > .contentWrapper > .poweredBy {
  position: absolute;
  bottom: 4vw;
  right: 6vw;
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  font-weight: lighter;
  line-height: 2.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-align: center;
  letter-spacing: 0.2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.portrait > .moreInfoContent > .contentWrapper > .poweredBy > svg {
  width: 30vw;
}

.portrait > .moreInfoContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
}

.portrait > .moreInfoContent > .contentWrapper {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(var(--main-color));
  width: 90vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5vw;
}

.portrait > .moreInfoContent > .contentWrapper > svg {
  position: absolute;
  right: 4vw;
  top: 2vw;
  width: 12vw;
  height: auto;
}

.contentWrapper > svg > * {
  fill: #ffffff;
}

.portrait > .moreInfoContent > .contentWrapper > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3.5vw;
  font-weight: bold;
  line-height: 2.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-align: left;
  letter-spacing: 0.2vw;
  margin-top: 6vw;
}

.portrait > .moreInfoContent > .contentWrapper > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3.2vw;
  font-weight: lighter;
  line-height: 2.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-align: left;
  letter-spacing: 0.2vw;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.portrait > .moreInfoContent > .contentWrapper > p > svg {
  width: 5vw;
  height: auto;
}

.portrait > .moreInfoContent > .contentWrapper > p > svg > * {
  fill: #ffffff;
}

.landscape {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landscape > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landscape > .background > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.landscape > .logo {
  position: fixed;
  top: 4vw;
  left: 4vw;
  z-index: 1;
  width: 6vw;
}

.landscape > h1 {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4vw;
  line-height: 1.6;
  text-align: center;
  color: #ffffff;
  margin: 0;
  letter-spacing: 0.4vw;
}

.landscape > p {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  font-weight: lighter;
  line-height: 1.4;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-top: 2vw;
  letter-spacing: 0.4vw;
  white-space: pre-wrap;
  width: 80%;
}

.landscape > .explore-btn {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  font-weight: lighter;
  line-height: 3;
  color: #ffffff;
  margin: 0;
  margin-top: 4vw;
  text-align: center;
  letter-spacing: 0.4vw;
  background: rgb(var(--main-color));
  width: 30vw;
}

.landscape > .moreInfo {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  font-weight: lighter;
  line-height: 1.4;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-top: 4vw;
  letter-spacing: 0.4vw;
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy {
  position: absolute;
  bottom: 2vw;
  right: 4vw;
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.2vw;
  font-weight: lighter;
  line-height: 2.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-align: center;
  letter-spacing: 0.2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy > svg {
  width: 20vw;
}

.landscape > .moreInfoContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
}

.landscape > .moreInfoContent > .contentWrapper {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(var(--main-color));
  width: 90vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15vw;
}

.landscape > .moreInfoContent > .contentWrapper > svg {
  position: absolute;
  right: 4vw;
  top: 2vw;
  width: 8vw;
  height: auto;
}

.landscape > .moreInfoContent > .contentWrapper > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  font-weight: bold;
  line-height: 2.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-align: left;
  letter-spacing: 0.2vw;
  margin-top: 4vw;
}

.landscape > .moreInfoContent > .contentWrapper > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.8vw;
  font-weight: lighter;
  line-height: 2.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-align: left;
  letter-spacing: 0.2vw;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg {
  width: 3vw;
  height: auto;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg > * {
  fill: #ffffff;
}

.portrait-links {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
}

.portrait-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.portrait-links > svg {
  position: fixed;
  top: 10vw;
  left: 8vw;
  z-index: 1;
  width: 12vw;
}

.portrait-links > .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portrait-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8vw 8vw;
}

.portrait-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4vw;
  line-height: 1.4;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  margin: 0;
  letter-spacing: 0.4vw;
}

.portrait-links > .wrapper > .img-link > p,
.portrait-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3vw;
  font-weight: lighter;
  line-height: 1.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  margin-bottom: 4vw;
  letter-spacing: 0.4vw;
}

.portrait-links > .wrapper > .img-link > div > h2 {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3.5vw;
  font-weight: bold;
  line-height: 1.4%;
  text-align: left;
  color: #ffffff;
  margin: 0;
  margin-bottom: 4vw;
  letter-spacing: 0.4vw;
}

.landscape-links {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
  background-color: #25282e;
}

.landscape-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.landscape-links > svg {
  position: fixed;
  top: 4vw;
  left: 4vw;
  z-index: 1;
  width: 8vw;
}

.landscape-links > .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landscape-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 70vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4vw 4vw;
  margin-right: 2vw;
}

.landscape-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4vw;
  line-height: 1.4;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  margin: 0;
  letter-spacing: 0.4vw;
}

.landscape-links > .wrapper > .img-link > p,
.landscape-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  font-weight: lighter;
  line-height: 1.4;
  text-align: left;
  color: #ffffff;
  margin: 0;
  margin-bottom: 4vw;
  letter-spacing: 0.4vw;
}

.landscape-links > .wrapper > .img-link > div > h2 {
  z-index: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3.5vw;
  font-weight: bold;
  line-height: 1.4%;
  text-align: left;
  color: #ffffff;
  margin: 0;
  margin-bottom: 4vw;
  letter-spacing: 0.4vw;
}
