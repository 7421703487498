.hotspot-article > svg {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 35px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
  cursor: pointer;
}

.hotspot-article > svg:hover {
  width: 60px;
}

.hotspot-article > svg * {
  filter: none;
}
