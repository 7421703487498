.container-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pulse-3d {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
}
