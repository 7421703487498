@font-face {
  font-family: 'Myriad Pro';
  src: url(../../fonts/MYRIADPRO-REGULAR.OTF) format('truetype');
}

.viewer-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  z-index: 99;
  transition: width 0.5s ease, height 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  width: 75vw;
  height: 40vw;
}

.viewer-modal > .viewer-title {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgb(var(--main-color));
}

.viewer-modal > .viewer-title > .close-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--main-dark));
}

.viewer-modal > .viewer-body > .specs-container > .specs-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #d0d0d0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-btns > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1;
  margin-left: 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-btns > svg > * {
  fill: rgb(var(--main-color));
}

.viewer-modal > .viewer-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.viewer-modal > .viewer-body > .specs-container {
  height: 100%;
  width: 34%;
  background: #ffffff;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-title {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-title > h1 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 0;
  padding: 0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body {
  padding: 16px 0 0 0;
  overflow: scroll;
  max-height: 90%;
}

@media only screen and (max-width: 1400px) {
  .viewer-modal > .viewer-body > .specs-container > .specs-body {
    padding: 16px 0 0 0;
    overflow: scroll;
    max-height: 85%;
  }
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .specs-description {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.3vw;
  letter-spacing: 0.005em;
  color: #3e3f40;
  max-width: 100%;
  margin: 0;
  padding: 0 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > h2 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2vw;
  line-height: 1.2vw;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 32px 16px 16px 16px;
  padding: 0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .feature-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-bottom: 1px solid #d0d0d0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .feature-container > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.3vw;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 0;
  padding: 0 0 0 16px;
  width: 40%;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .feature-container > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 160%;
  white-space: pre-wrap;
  letter-spacing: 0.005em;
  color: #3e3f40;
  margin: 0;
  padding: 0;
  width: 60%;
  padding-right: 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button > .border {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(var(--main-color));
  border-radius: 50%;
  cursor: pointer;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button > .border > svg {
  width: 24px;
  height: 24px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 106%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: rgb(var(--main-color));
}

.viewer-modal
  > .viewer-body
  > .specs-container
  > .specs-buttons
  > .specs-button
  > .border
  > svg
  > * {
  fill: rgb(var(--main-color));
}

.viewer-modal > .viewer-body > .viewer-container {
  width: 66%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cdced0;
}

.viewer-modal > .viewer-body > .viewer-container > .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(40, 40, 40, 0.7);
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg > .tri {
  opacity: 0;
  fill: #ffffff;
  animation: fade ease-in 3s;
  animation-iteration-count: infinite;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg {
  width: 100px;
  height: auto;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg > .tri.two {
  animation-delay: 0.25s;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg > .tri.three {
  animation-delay: 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}

.viewer-modal > .viewer-body > .viewer-container > .loader > .pb {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 12px;
  text-transform: lowercase;
  color: #ffffff;
  z-index: 1;
  margin-top: 16px;
  width: 100px;
  text-align: left;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > .av-logo {
  width: 100px;
  height: auto;
}

.viewer-modal > .viewer-body > .viewer-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
}

.viewer-modal > .viewer-body > .viewer-container > img.bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: auto;
  width: 100%;
  pointer-events: none;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer3d {
  display: flex;
  align-items: center;
  justify-content: center;
  /*height: 100% !important;
  width: 100% !important;
  /*background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(120, 120, 120, 1) 0%,
    rgba(80, 80, 80, 1) 100%
  );*/
  /*background: url('/public/assets/img/viewer_bg.png');*/
}

/*.viewer-modal > .viewer-body > .viewer-container > .viewer3d > canvas {
  width: 100% !important;
  height: 100% !important;
}*/

.viewer-modal > .viewer-body > .viewer-container > .viewer-toolbar {
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-toolbar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
  cursor: pointer;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-toolbar > svg > * {
  fill: #ffffff;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives > svg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
  cursor: pointer;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives > svg > * {
  fill: #ffffff;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives > .perspective-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 3.2px #000000;
  margin-left: 16px;
}

.tutorial-screen {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(40, 40, 40, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tutorial-screen > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
  white-space: pre-wrap;
  text-shadow: 0px 1px 4px #000000;
}

.head-title > .new-label {
  transform: skew(-15deg);
  height: auto;
  opacity: 1;
  cursor: auto;
  font-family: Myriad Pro;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: #e84855;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6);
  padding: 0 5px 0 1px;
  margin: 4px 0 0 4px;
}

/*mobile*/
.viewer-modal-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: 60vh;
}

.viewer-modal-mobile > .viewer-container-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cdced0;
}

.viewer-modal-mobile > .viewer-container-mobile.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
}

.viewer-modal-mobile > .viewer-info-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  transition: height 0.3s ease-in-out;
  background: #ffffff;
  z-index: 1;
  overflow: hidden;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > svg.arrow {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > svg.arrow > * {
  fill: rgb(var(--main-color));
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > h1 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 64px;
  top: 50%;
  transform: translate(0, -50%);
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > h1 > svg.new {
  width: 24px;
  height: auto;
  margin: 0 0 4px 0;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > .header-btns-mobile {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > .header-btns-mobile > svg {
  margin-right: 20px;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > .header-btns-mobile > svg > * {
  fill: rgb(var(--main-color));
}

.viewer-modal-mobile > .viewer-container-mobile > .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(40, 40, 40, 0.7);
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg > .tri {
  opacity: 0;
  fill: #ffffff;
  animation: fade ease-in 3s;
  animation-iteration-count: infinite;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg {
  width: 60px;
  height: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg > .tri.two {
  animation-delay: 0.25s;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg > .tri.three {
  animation-delay: 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > .pb {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 12px;
  text-transform: lowercase;
  color: #ffffff;
  z-index: 1;
  margin-top: 16px;
  width: 100px;
  text-align: left;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > .av-logo {
  width: 100px;
  height: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .tutorial-screen-mobile {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(40, 40, 40, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewer-modal-mobile > .viewer-container-mobile > .bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile {
  height: 75%;
  overflow: scroll;
}

/*.viewer-modal-mobile > .viewer-info-mobile > .info-mobile::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar 
  height: 0;
}*/

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .specs-description {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #3e3f40;
  max-width: 100%;
  margin: 0;
  padding: 0 20px;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > h2 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 16px 8px 8px 8px;
  padding: 0;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .feature-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-bottom: 1px solid #d0d0d0;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .feature-container > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 0;
  padding: 0 0 0 20px;
  width: 50%;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .feature-container > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  white-space: pre-wrap;
  letter-spacing: 0.005em;
  color: #3e3f40;
  margin: 0;
  padding: 0;
  width: 50%;
  padding-right: 20px;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-toolbar {
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewer-modal-mobile > .viewer-container-mobile.fullscreen > .viewer-toolbar {
  top: 16px;
  bottom: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-toolbar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  filter: drop-shadow(0px 1.6px 1.2px #000000);
  cursor: pointer;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-toolbar > svg > * {
  fill: #ffffff;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-perspectives {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.viewer-modal-mobile > .viewer-container-mobile.fullscreen > .viewer-perspectives {
  top: 16px;
  bottom: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-perspectives > svg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  filter: drop-shadow(0px 1.6px 1.2px #000000);
  cursor: pointer;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-perspectives > svg > * {
  fill: #ffffff;
}
