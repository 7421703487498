.hotspot-container {
  z-index: 299;
  pointer-events: none;
}

.permalink-focus {
  width: auto;
  height: 3.5vh;
  filter: drop-shadow(0px 1.66667px 3.33333px #000000);
}
