.greeting-container{
    width: 100%;
    height: 43px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;

    .greeting-text{
        padding-left: 4.8%;
    }
}



