.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

@media only screen and (max-width: 720px) {
  .login-container {
    height: 250px !important;
  }

  .login-container > form > input {
    margin-top: 8px !important;
  }
}

.login-container > h1 {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin: 0;
  padding: 0;
}

.login-container > form {
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container > form > label {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
}

.login-container > form > input {
  height: 48px;
  width: 100%;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #808080;
  padding: 12px;
  margin-top: 16px;
}

.login-container > form > input:focus {
  outline: none;
}

.login-container > form > input.submit {
  width: 100%;
  height: 48px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(var(--main-color));
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
