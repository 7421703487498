.pulse-container {
  position: absolute;
  width: 0;
  height: 0;
  background: black;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.pulse-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-color: rgb(var(--main-color));
  width: 100px;
  height: 100px;
  opacity: 0;
  z-index: 100;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
  animation: scaleIn 1.5s infinite cubic-bezier(0.2, 0.2, 0.2, 0.2);
}

.pulse-animation::after {
  transform: translate(-50px, -50px);
}

@keyframes scaleIn {
  from {
    transform: translate(-50px, -50px) scale(0.25, 0.25);
    opacity: 0.5;
  }
  to {
    transform: translate(-50px, -50px) scale(1, 1);
    opacity: 0;
  }
}
