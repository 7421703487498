.tool-day-night {
  width: 24px;
  height: 24px;
}

.tool-day-night > svg {
  width: 100%;
  height: auto;
  cursor: pointer;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.tool-day-night > svg > * {
  fill: #ffffff;
}

.tool-day-night > svg * {
  filter: none;
}
