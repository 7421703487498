.hotspot-pa .content {
  position: absolute;
  width: 25vw;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;

  -webkit-transform-origin: 50% calc(100% + 6em);
  transform-origin: 50% calc(100% + 6em);

  -webkit-transform: translate3d(-50%, -100%, 0) translate3d(0, -45px, 0);
  transform: translate3d(-50%, -100%, 0) translate3d(0, -45px, 0);

  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;

  -webkit-transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.hotspot-pa .content.active {
  opacity: 0.9;
}

.hotspot-pa .content h3 {
  background: rgb(var(--main-color));
  border-radius: 0px;
  margin: 0;
  padding: 10px 18px 12px 18px;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}

.hotspot-pa .content p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #141414;
  margin: 0;
  padding: 13px 18px 16px 18px;
}

.hotspot-pa > svg.hotspot {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 4.2vh;
  opacity: 0.9;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
  cursor: pointer;
}

.hotspot-pa > svg.hotspot *,
.hotspot-pa > .hotspot-hover * {
  filter: none;
}

.hotspot-pa .hotspot-hover {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 5.9vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
}

.hotspot-pa .hotspot-hover.active {
  opacity: 1;
}

.hotspot-pa:hover .hotspot-hover {
  opacity: 1;
}

.hotspot-pa .hotspot-oval {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 10.3vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.hotspot-pa .hotspot-oval.active {
  opacity: 1;
}

.pa-hotspot-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  width: 100vw;
  height: 10vh;
}

.pa-hotspot-container.active {
  opacity: 1;
  pointer-events: all;
}

.pa-hotspot-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.pa-hotspot-container > .mobile-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.pa-hotspot-container > .mobile-content > h3 {
  background: rgb(var(--main-color));
  border-radius: 10px 10px 0px 0px;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3vw;
  line-height: 3.2vw;
  color: #ffffff;

  margin: 0;
  padding: 0 0 0 2.5vw;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pa-hotspot-container > .mobile-content > h3 > .close {
  background: rgb(var(--main-dark));
  border-radius: 0px 10px 0px 0px;

  margin: 0;
  padding: 1.2vw 1.5vw;
}

.pa-hotspot-container > .mobile-content > svg {
  width: 6vw;
}

.pa-hotspot-container > .mobile-content > h3 > .close > img {
  width: 4vw;
  height: auto;
}

.pa-hotspot-container > .mobile-content > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;
  /* or 144% */

  color: #141414;
  height: 73%;
  margin: 0;
  padding: 2vw 4vw;

  overflow-y: auto;
}
