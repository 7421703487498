.dropdown-container {
  position: fixed;
  top: 7vh;
  right: 5vw;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.dropdown-container.vert {
  position: fixed;
  top: 7vh;
  right: 1vw;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.dropdown-container > .dropdown-language {
  width: 6.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropdown-container.vert > .dropdown-language {
  width: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropdown-container > .dropdown-language > svg {
  height: auto;
  filter: drop-shadow(0px 1.66667px 3.33333px #000000);
}

.dropdown-container > .language-list {
  display: none;
  background: #ffffff;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 1.2vw;
  opacity: 0;
  pointer-events: none;
  height: 0;
}

.dropdown-container:hover > .language-list {
  opacity: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: height 2s ease;
  pointer-events: all;
}

.dropdown-container > .language-list > .language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown-container > .language-list > .language > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.2vw;
  line-height: 110%;

  color: #000000;
  margin: 0.3vw;
}

.dropdown-container.vert > .language-list > .language > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4vw;
  line-height: 110%;

  color: #000000;
  margin: 2vw;
}

.dropdown-container > .language-list > .language > img {
  width: 1.8vw;
  margin-right: 0.6vw;
}

.dropdown-container.vert > .language-list > .language > img {
  width: 6vw;
  margin-right: 0.6vw;
}
