.navBar-modal-det,
.modal-det {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 99;
  transition: width 0.5s ease, height 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.navBar-modal-det > .head,
.modal-det > .head {
  width: 100%;
  height: 4.44vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navBar-modal-det > .head > h1,
.modal-det > .head > h1 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.04vw;
  line-height: 100%;
  display: flex;
  align-items: center;

  /* white */

  color: #ffffff;
  margin: 0;
  background: rgb(var(--main-color));
  width: 100%;
  padding: 1.49vh 0.83vw;
}

.navBar-modal-det > .head > .svg-container {
  width: 2.5vw;
  height: 4.44vh;
  background: rgb(var(--main-dark));
}

.navBar-modal-det > .head > .svg-container > svg {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navBar-modal-det > .head > .svg-container > svg > * {
  fill: #ffffff;
}

.navBar-modal-det > .content {
  background: #ffffff;
  padding: 1.49vh 0.83vw;
}

.navBar-modal-det > .content > .main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.navBar-modal-det > .content > .main-container > img {
  max-height: 55.56vh;
  width: auto;
}

.navBar-modal-det > .content > .main-container > .details {
  width: 18.33vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 16px;
  max-height: 55.56vh;
  overflow: scroll;
}

/*.navBar-modal-det > .content > .main-container > .details::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar 
  height: 0;
}*/

.navBar-modal-det > .content > .main-container > .details > .chars > h2 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 8px 0 0 0;
}

.navBar-modal-det > .content > .main-container > .details > .chars > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin: 16px 0 32px 0;
}

.navBar-modal-det > .content > .main-container > .details > .list > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.0015em;
  color: #000004;
  margin: 0;
}

.navBar-modal-det > .content > .main-container > .details > .list > ul {
  margin: 16px 0 32px 0;
  padding: 0 16px;
}

.navBar-modal-det > .content > .main-container > .details > .list > ul > li {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin-top: 8px;
}

.navBar-modal-det > .content > .main-container > .details > .list > ul > li::marker {
  color: #000004;
  font-size: 0.5em;
}

.navBar-modal-det > .content > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding-top: 16px;
}

.modal-det > .content > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  overflow-x: scroll;
  padding-top: 16px;
}

.navBar-modal-det > .content > .selector > .selector-img-container,
.modal-det > .content > .selector > .selector-img-container {
  position: relative;
  opacity: 0.5;
  height: 7.78vh;
  width: 6.56vw;
  transition: opacity 0.3s ease;
  cursor: pointer;
  margin-right: 16px;
  overflow: hidden;
}

.navBar-modal-det > .content > .selector > .selector-img-container > .selector-img,
.modal-det > .content > .selector > .selector-img-container > .selector-img {
  width: 120%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navBar-modal-det > .content > .selector > .selector-img-container:hover {
  opacity: 1;
}

.navBar-modal-det > .content > .selector > .selector-img-container.active {
  opacity: 1;
}

.navBar-modal-det > .content > .selector > .selector-img-container > .border {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.navBar-modal-det > .content > .selector > .selector-img-container.active > .border {
  opacity: 1;
  border: 3px solid rgb(var(--main-color));
}

.mobile-modal-det {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-det > .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobile-modal-det > .header > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 16px 0 16px;
  color: #ffffff;
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
}

.mobile-modal-det > .header > .svg-container {
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--main-dark));
}

.mobile-modal-det > .header > .svg-container > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-det > .header > .svg-container > svg > * {
  fill: #ffffff;
}

.mobile-modal-det > .footer {
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  background: #ffffff;
  border-top: 1px solid #808080;
}

.mobile-modal-det > .footer > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-modal-det > .footer > svg > * {
  fill: #808080;
}

.mobile-modal-det > .footer > svg.active > * {
  fill: rgb(var(--main-color));
}

.mobile-modal-det > .image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: hidden;
}

.mobile-modal-det > .image > .container {
  width: 80%;
  height: 100%;
  overflow: hidden;
}

.mobile-modal-det > .image > .container > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.mobile-modal-det > .image > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-det > .image > svg > * {
  fill: #808080;
}

.mobile-modal-det > .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: scroll;
}

/*.mobile-modal-det > .info::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar 
  height: 0;
}*/

.mobile-modal-det > .info > .chars > h2 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 0 0 0 0;
  width: 100%;
}

.mobile-modal-det > .info > .chars > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin: 16px 0 32px 0;
  width: 100%;
}

.mobile-modal-det > .info > .list {
  width: 100%;
}

.mobile-modal-det > .info > .list > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.0015em;
  color: #000004;
  margin: 0;
  width: 100%;
}

.mobile-modal-det > .info > .list > ul {
  margin: 16px 0 32px 0;
  padding: 0 16px;
}

.mobile-modal-det > .info > .list > ul > li {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin-top: 8px;
}

.mobile-modal-det > .info > .list > ul > li::marker {
  color: #000004;
  font-size: 0.5em;
}

.mobile-modal-det > .collection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: scroll;
}

/*.mobile-modal-det > .collection::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar 
  height: 0;
}*/

.mobile-modal-det > .collection > .selector-img-container {
  width: 30%;
  height: 100px;
}

.mobile-modal-det > .collection > .selector-img-container > img {
  width: 100%;
  height: 100%;
}

.full {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  background: #000004;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  pointer-events: all;
  padding: 0 16px;
}

.full > svg {
  width: 24px;
  height: 24px;
}

.full > svg > * {
  fill: #808080;
}

.full > img {
  height: 100%;
  max-width: 90%;
}

.full > .full-close {
  position: absolute;
  top: 16px;
  right: 16px;
}
