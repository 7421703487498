.vg-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 199;
  transition: width 0.5s ease, height 0.5s ease;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vg-modal > iframe {
  width: 60vw;
  height: 60vh;
}

.vg-modal > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* davys-grey-100 */

  color: #000004;
  margin: 16px 0 0 0;
  padding: 0;
  width: 100%;
}

.vg-modal > .thumbs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 84px;
  width: 100%;
  margin-top: 16px;
}

.vg-modal > .thumbs-container > img {
  height: 100%;
  width: auto;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 16px;
}

.vg-modal > .thumbs-container > img:first-child {
  margin-left: 0;
}

.vg-modal > .thumbs-container > img.selected {
  opacity: 1;
}

/*mobile*/
.vg-modal-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 199;
  transition: width 0.5s ease, height 0.5s ease;
  width: calc(50vw + 116px);
  height: 65vh;
  overflow: hidden;
}

.vg-modal-mobile > iframe {
  width: 50vw;
  height: 55vh;
  position: absolute;
  top: 8px;
  left: 8px;
}

.vg-modal-mobile > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000004;
  margin: 8px 0 0 0;
  padding: 0;
  width: 100%;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.vg-modal-mobile > .thumbs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 100%;
  transition: height 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 8px 8px 0;
  overflow: scroll;
}

.vg-modal-mobile > .thumbs-container > img {
  height: auto;
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
}

.vg-modal-mobile > .thumbs-container > img:first-child {
  margin-left: 0;
}

.vg-modal-mobile > .thumbs-container > img.selected {
  opacity: 1;
}
