.hotspot-link {
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hotspot-link:hover {
  z-index: 11;
}

.hotspot-link .hotspot {
  position: absolute;
  opacity: 0.8;
  width: auto;
  height: 5.9vh;
  cursor: pointer;
}

.hotspot-link .hotspot.active {
  opacity: 1;
}

.hotspot-link:hover .hotspot {
  opacity: 1;
}

.hotspot-link > svg {
  position: absolute;
  width: 40px;
  height: auto;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  /*filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));*/
}

.hotspot-link > svg:hover {
  opacity: 1;
}

.hotspot-link > svg * {
  filter: none;
}

.hotspot-link > .tooltip {
  position: absolute;
  transform: translate(-50%, -50%);
  opacity: 0;
  background: #ffffff;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: #000004;
  overflow: hidden;
  padding: 8px;
  pointer-events: none;
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hotspot-link:hover > .tooltip,
.hotspot-link > .tooltip.hovered {
  opacity: 0.9;
  transform: translate(25px, -50%);
}

.hotspot-link > .tooltip > h6 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.0015em;
  color: #000004;
  margin: 0 0 8px 0;
}

.hotspot-link > .tooltip > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.0015em;
  color: #464646;
  margin: 0;
  white-space: pre;
}

.hotspot-link > .arrow-container {
  width: 60px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hotspot-link > .arrow-container > .arrow {
  animation: fade-arrow;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 4s;
  opacity: 1;
}

.hotspot-link > .arrow-container > .arrow > * {
  fill: rgb(var(--main-color));
}

.hotspot-link > .arrow-container > .arrow.two {
  animation-delay: 0.2s;
}

.hotspot-link > .arrow-container > .arrow.three {
  animation-delay: 0.4s;
}

@keyframes fade-arrow {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}
