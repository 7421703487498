.remote-bar {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  padding: 4px;
}

.remote-bar > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.remote-bar > svg > * {
  fill: #ffffff;
}

.media-info {
  height: 24px;
}

.media-info > svg {
  width: 24px;
  height: 100%;
}

.media-info > svg > * {
  fill: #ffffff;
}

.remote-tools {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background: #212221;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
}

.remote-tools.active {
  opacity: 1;
  pointer-events: all;
}

.remote-tool {
  width: 100%;
  height: 60px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #ffffff;
  cursor: pointer;
  text-align: left;
}

.remote-tool > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.remote-tool > svg > * {
  fill: #ffffff;
}

.prompt-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
}

.changed-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  height: 277px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.changed-container > svg {
  width: 70px;
  height: 70px;
}

.changed-container > svg > * {
  fill: #000000;
}

.changed-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: start;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.changed-container > p > svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  vertical-align: text-bottom;
}

.changed-container > p > svg > * {
  fill: #000000;
}

.changed-container > p > strong {
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 20px;
}

.changed-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.changed-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: rgb(var(--main-color));
  width: 116px;
  height: 36px;
}
