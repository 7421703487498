.map-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 22vw;
  height: auto;
  margin: 5vh 6vw 0 0;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.map-container.active {
  opacity: 0.35;
  pointer-events: all;
}

.map-container.active:hover {
  opacity: 1;
}

.map-container h3 {
  flex: 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.7vw;
  line-height: 2.3vw;
  margin: 2.5vh 0 0 15px;
  padding: 0 6px;
  color: #3d3d3d;
  align-self: flex-start;
}

.map-container .info-container {
  flex: 5;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 2.5vh 0 2.5vh 11px;
}

.map-container .map,
.mobile-map > .map {
  flex: 5;
  position: relative;
}

.map-container .map div,
.mobile-map > .map div {
  width: 100%;
  height: 100%;
}

.map-container .map svg,
.mobile-map > .map svg {
  height: 100%;
  width: 100%;
}

.map-container .map svg path,
.mobile-map > .map svg path {
  fill: rgba(var(--main-color), 0);
  transition: fill 0.3s ease;
}

.map-container .map svg > .hoverable:hover {
  fill: rgba(var(--main-color), 0.5);
}

#tooltip {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 10%;
  left: 30%;
  color: red;
  display: none;
  z-index: 10;
  pointer-events: none;
}

.hovering {
  display: block !important;
}

.map-container .map svg .selected,
.mobile-map > .map svg .selected {
  fill: rgba(var(--main-color), 0.8);
}

.map-container .sector-container,
.mobile-map > .sector-container {
  position: relative;
  border-left: 1px solid #bfbfbf;
}

.map-container .sector-container p,
.mobile-map > .sector-container > p {
  font-family: 'Merriweather Sans' T;
  font-size: 2.5vh;
  line-height: 4vh;

  white-space: nowrap;
  padding: 0 6px;
  margin: 0;

  text-align: center;

  color: #000000;
  width: auto;
  height: 4vh;
}

.map-container .sector-container p.active,
.mobile-map > .sector-container > p.active {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vh;
  line-height: 4vh;

  text-align: center;

  color: #000000;
  width: auto;
  height: 4vh;

  background: rgba(var(--main-color), 0.5);
}

.map-container .sector-container p:hover {
  background: rgba(var(--main-color), 0.2);
}

.map-container .map svg .hoverable title {
  position: absolute;
  display: block;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 35px;
  padding: 0 12px 2px 12px;
  text-align: center;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  color: #000000;
  bottom: 100%;

  transition: opacity 0.3s ease;
}

.mobile-map-container {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  width: 100vw;
  height: 100vh;
}

.mobile-map-container.active {
  opacity: 1;
  pointer-events: all;
}

.mobile-map-container > .mobile-map {
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  height: 75%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 2vw 0;
}

.mobile-map-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}
