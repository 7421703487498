.modal-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.modal-container.active {
  opacity: 1;
  pointer-events: all;
  z-index: 99;
}

.modal-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.hotspot-modal > svg.new-label {
  position: absolute;
  transform: translate(-50%, -200%);
  width: 40px;
  height: auto;
  opacity: 1;
  cursor: auto;
  transition: all ease 0.3s;
}

.hotspot-modal > svg.new-label.hover {
  transform: translate(-50%, -250%);
}

.hotspot-modal > svg.new-label:hover {
  width: 34px;
}

.hotspot-modal > div.new-label {
  position: absolute;
  transform: translate(-50%, -275%) skew(-15deg);
  height: auto;
  opacity: 1;
  cursor: auto;
  font-family: Myriad Pro;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: #e84855;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6);
  padding: 0 5px 0 1px;
}

.hotspot-modal > svg {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
  cursor: pointer;
}

.hotspot-modal > svg:hover,
.hotspot-modal > svg.hovered {
  width: 60px;
}

.hotspot-modal > svg * {
  filter: none;
}

.hotspot-modal > .hotspot {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 5.9vh;
  opacity: 0.9;
}

.hotspot-modal > .hotspot-hover {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 8vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.hotspot-modal > .hotspot-hover.active {
  opacity: 1;
}

.hotspot-modal:hover > .hotspot-hover {
  opacity: 1;
}
