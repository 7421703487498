.closed-map-icon {
  position: fixed;
  right: 2.4vw;
  top: 4.63vh;
  z-index: 90;
  cursor: pointer;
}

.closed-map-icon > svg {
  width: 60px;
  height: auto;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
}

.closed-map-icon > svg * {
  filter: none;
}

.map-wrapper {
  position: fixed;
  right: 2.4vw;
  top: 4.63vh;
  height: auto;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.48vh 0.83vw;
  opacity: 0.35;
  transition: opacity 0.5s ease;
}

.map-wrapper:hover {
  opacity: 1;
}

.map-wrapper > .btn-container {
  width: 100%;
  height: 2.96vh;
  margin-bottom: 1.48vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.map-wrapper > .btn-container > .direction-btn {
  position: absolute;
  left: 0;
  width: auto;
  height: 100%;
  padding: 0.74vh 0.83vw 0.74vh 0.73vw;
  background: rgb(var(--main-color));
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.map-wrapper > .btn-container > .direction-btn > svg {
  width: 24px;
  cursor: pointer;
}

.map-wrapper > .btn-container > .direction-btn > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;
}

.map-wrapper > .btn-container > svg {
  width: 24px;
  height: auto;
  margin-left: 16px;
  cursor: pointer;
}

.map-wrapper > .btn-container > svg > * {
  fill: #101010;
}

.maps-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 89;
  width: 100vw;
  height: 100vh;
  background: rgba(35, 35, 35, 0.5);
}

.mobile-maps-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: auto;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0.3vw 1vw 1vw 1vw;
  z-index: 398;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.mobile-maps-wrapper > .mobile-btn-container {
  width: 100%;
  height: 4vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.mobile-maps-wrapper > .mobile-btn-container > svg {
  width: 3.5vw;
  margin-left: 2vw;
}

.mobile-maps-wrapper > .mobile-btn-container > svg > * {
  fill: #101010;
}

.mobile-maps-wrapper > .mobile-btn-container > .direction-btn {
  position: absolute;
  left: 0;
  width: 14vw;
  height: auto;
  padding: 0 1vw 0 0.6vw;
  background: rgb(var(--main-color));
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mobile-maps-wrapper > .mobile-btn-container > .direction-btn > svg {
  width: 3vw;
  height: auto;
}

.mobile-maps-wrapper > .mobile-btn-container > .direction-btn > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.7vw;
  line-height: 114%;
  padding: 1px 0 0 0;
  margin: 0;
  /* identical to box height, or 114% */

  /* White */

  color: #ffffff;
}
