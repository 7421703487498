.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(35, 35, 35, 0.5);
  z-index: 10;
  pointer-events: none;
}

.overlay.inactive {
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease;
}

.overlay .info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.overlay .info .infoBox {
  width: 33%;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0 16px;
}

.overlay .info .infoBox p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3vh;
  line-height: 120%;

  text-align: center;

  color: #000000;
  margin: 3vh 0 4vh 0;
}

.overlay .info .infoBox .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.overlay .info .infoBox .row .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.overlay .info .infoBox .row .column .img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 17px 0 15px 0;
}

.overlay > .info > .infoBox > .row > .column > .img-wrapper > svg > * {
  stroke: rgb(var(--main-color));
}

.overlay .info .infoBox .row .column .img-wrapper.border {
  border-left: 1px solid rgb(var(--main-color));
}

.overlay .info .infoBox .row .column p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vh;
  line-height: 110%;

  text-align: center;

  color: #000000;
  margin: 2vh 0 3vh 0;
}

.overlay .footer {
  visibility: visible;
  position: fixed;
  bottom: 6%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-end;
}

.overlay .footer .left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.overlay .footer .center {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay .footer .center p {
  align-self: center;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.8vw;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0.7vw 0;

  color: #ffffff;

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlay .footer .center img {
  width: 14vw;
  height: auto;
  align-self: center;
}

.overlay .footer .center > svg {
  width: 14vw;
  height: auto;
  align-self: center;
}

.overlay .footer .right {
  visibility: visible;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.overlay .footer .right .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.overlay .footer .right p {
  align-self: flex-start;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.8vw;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0.5vw 0;

  color: #ffffff;

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlay .footer .right img {
  width: 8vw;
  height: auto;
  margin-right: 2vw;
}

.overlay > .footer > .right > .row > svg {
  width: 8vw;
  height: auto;
  margin-right: 2vw;
}
